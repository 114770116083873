// this script checks if the user is logged in to woorank and
// if the private key matches
import { collectWebVitals } from './webVitals.ts';

try {
  const insertSafariStorageFrame = () => {
    if (document.hasStorageAccess && new URL(window.location.href).searchParams.get('init-assistant') === '1') {
      // create eventlistner for the message from the frame
      // that is fired when the user has granted storage access
      window.addEventListener('message', (event) => {
        const host = new URL(event.origin).host;
        const allowedHosts = ['www.woorank.com', 'woorank.com', 'assistant.woorank.com'];
        if (event.data === 'storage-access-granted' && allowedHosts.includes(host)) {
          // reload the page so that the user can see the assistant
          window.location.reload();
        }
      }, false);

      // inject the frame that deals with requesting storage access
      const frame = document.createElement('iframe');
      frame.src = 'https://assistant.woorank.com/hydra/assistant-storage.html';
      frame.style = 'position: abolute; top: 0; left: 0; width: 100px; height: 100px; border: none; position:absolute;z-index:2147483647';
      document.body.appendChild(frame);
    }
  };
  const blockUserAgent = () => {
    if (!window.navigator || !window.navigator.userAgent) {
      return true;
    }
    const blockedUserAgents = [
      'spider',
      'crawl',
      'bingPreview',
      'iphone',
      'android',
      'HeadlessChrome',
      'bot'
    ];
    const userAgent = window.navigator.userAgent;
    return blockedUserAgents.some(v => userAgent.toLowerCase().includes(v.toLowerCase()));
  };
  const getOptions = () => {
    if (window.woorankAssistantOptions) {
      return window.woorankAssistantOptions;
    }

    // this allows setting of the options by appending the correct keys to the script src
    if (document.currentScript && document.currentScript.src) {
      const scriptQueryParms = new URL(document.currentScript.src).searchParams;
      if (scriptQueryParms.toString().length === 0) {
        return {};
      }
      return {
        url: scriptQueryParms.get('url'),
        assistantPublicKey: scriptQueryParms.get('assistantPublicKey'),
        collectWebVitals: !!scriptQueryParms.get('collectWebVitals')
      };
    }
    return {};
  };
  const inFrameOrEmbed = () => {
    if (window.woorankAssistantOptions.bypassIframe) {
      return false;
    }

    return window.self !== window.top;
  };
  const urlMatch = () => {
    if (!window.woorankAssistantOptions.url) {
      return true;
    }
    const windowHost = new URL(window.location).host.replace(/^www\./, '').toLowerCase();
    if (typeof windowHost.endsWith === 'function' && windowHost.endsWith('duda.co')) {
      return true;
    }
    const woorankUrl = window.woorankAssistantOptions.url.replace(/^www\./, '').toLowerCase();
    return windowHost === woorankUrl;
  };
  const loadWoorankWidget = async () => {
    window.woorankAssistantOptions = getOptions();
    if (!urlMatch()) {
      return;
    }
    const woorankRoot = window.woorankAssistantOptions.root || 'https://www.woorank.com/';
    const useLambda = window.woorankAssistantOptions.useLambda || false;
    const rootsOnCDN = ['https://www.woorank.com/', 'https://wwwtest.woorank.com/'];
    const scriptRoot = rootsOnCDN.includes(woorankRoot) ? 'https://assets.woorank.com/hydra/' : woorankRoot + 'dev-cdn/';
    const pubKey = window.woorankAssistantOptions.assistantPublicKey || 'na';
    collectWebVitals({
      woorankRootUrl: woorankRoot,
      document,
      pubKey,
      useLambda,
      navigator,
      userAgent: window.navigator.userAgent
    });
    if (inFrameOrEmbed() || blockUserAgent()) {
      return;
    }

    const apiEndpoint = new URL(woorankRoot + 'en/onpage-assistant/check');
    apiEndpoint.searchParams.append('url', window.location.href);
    apiEndpoint.searchParams.append('projectUrl', window.woorankAssistantOptions.url);
    if (window.woorankAssistantOptions.assistantPublicKey) {
      apiEndpoint.searchParams.append('pubkey', window.woorankAssistantOptions.assistantPublicKey);
    }
    if (window.woorankAssistantOptions.context && typeof window.woorankAssistantOptions.context === 'string') {
      apiEndpoint.searchParams.append('context', window.woorankAssistantOptions.context);
    }
    if (window.dmAPI && window.dmAPI.getCurrentEnvironment && window.dmAPI.getCurrentEnvironment() === 'editor') {
      apiEndpoint.searchParams.append('currentEnvironment', 'editor');
    }
    if (window.dmAPI && window.dmAPI.getSiteName && window.dmAPI.getSiteName()) {
      apiEndpoint.searchParams.append('siteName', window.dmAPI.getSiteName());
    }

    if (document.readyState === 'loading') {
      window.addEventListener('load', () => {
        insertSafariStorageFrame();
      });
    } else {
      insertSafariStorageFrame();
    }
    const res = await window.fetch(apiEndpoint,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      }
    );
    if (res.status === 404) {
      return; // 404 = no user or user doesn't match, do nothing
    }
    try {
      const data = await res.json();
      if (data.success) {
        window.woorankAssistantOptions.daysInTrial = data.daysInTrial;
        window.woorankAssistantOptions.isInTrial = data.isInTrial;
        window.woorankAssistantOptions.trialEnded = data.trialEnded;
        document.body.appendChild(document.createElement('script')).src = `${scriptRoot}assistantWidget.latest.js`;
      } else if (data.userNotFound && window.woorankAssistantOptions.context === 'dudaEditor') {
        window.woorankAssistantOptions.userNotFound = true;
        document.body.appendChild(document.createElement('script')).src = `${scriptRoot}assistantWidget.latest.js`;
      }
    } catch (err) {
      console.error('error trying to load woorank assistant user data');
    }
  };
  loadWoorankWidget();
} catch (err) {
  console.error('Error loading woorank assistant');
}
